import React from "react";
import dynamic from "next/dynamic";
const ListVilla = dynamic(() => import("../ListVilla"));

import { GetVillasByKey } from "../api";
// import Lottie from "lottie-react";
import Link from "next/link";
import { useTranslation } from "../../context/TranslationContext";
const Products = ({ HomeContent, all }) => {
  const { allRentalVillas } = useTranslation();
  const style = {
    height: 50,
    width: 50,
  };
  return (
    <>
      {HomeContent &&
        HomeContent.map((item, key) => {
          return (
            <ListVilla
              key={key}
              title={item.title}
              description={item.kisa_icerik}
              baslik={item.baslik}
              showall={item.url}
              fetch={GetVillasByKey(item.key)}
              IconUrl={
                item.animation ? (
                  <></>
                ) : (
                  // <Lottie
                  //   animationData={JSON.parse(item.animation)}
                  //   style={style}
                  // ></Lottie>
                  ""
                )
              }
            />
          );
        })}
      <div className="text-center">
        <Link href={all.url}>
          <a
            title={all.title}
            className="bg-brand-blue-2 font-bold text-sm text-white py-5 px-8 inline-block mb-4 mx-auto lg:hidden "
          >
            {allRentalVillas}
          </a>
        </Link>
      </div>
    </>
  );
};

export default Products;
